import axios, { AxiosError, AxiosResponse } from "axios"
import { IAPIError, HTTP_METHOD, json_header, unwrapAxiosError } from "./helpers"
import { CustomerSignupModel, ValidateCustomerSignupModel } from "../models/CustomerSignup"

import CONSTS from "../CONSTS"

export const Signup = async (
    data: CustomerSignupModel
): Promise<CustomerSignupModel | IAPIError> => {
    return new Promise<CustomerSignupModel | IAPIError>((resolve, reject) => {
        let valid = ValidateCustomerSignupModel(data)

        if (true !== valid.ok) {
            return reject({
                raisedBy: "signup",
                error: `The ${valid.field} of is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: HTTP_METHOD.Post,
            url: "/admin/signup",
            baseURL: CONSTS.BACKEND_BASE_URL,
            data: data,
            validateStatus: (status) => {
                return status === 201
            },
            headers: json_header(),
        })
            .then((res: AxiosResponse) => {
                let data = res.data["signupData"]
                if (data === undefined) {
                    throw Error(
                        "Did not get the signup data back from the database. The return object might have changed in the backend, or something is wrong."
                    )
                }

                let valid = ValidateCustomerSignupModel(data)
                if (valid.ok === false) {
                    throw Error(
                        "The object we got back from the database was not a valid CustomerSignupModel. Please ensure that the models are in sync between the frontend and the backend."
                    )
                }

                resolve(data)
            })
            .catch((e: AxiosError) => {
                return reject(unwrapAxiosError(e))
            })
    })
}
