import React, { useState } from "react"
import { Link } from "react-router-dom"
import style from "./cookieBanner.module.css"
import GA4React from "ga-4-react"
const ga4react = new GA4React("G-VC9PGR955G")

function CookieBanner() {
    const [GACookiesPreferences, setGACookiesPreferences] = useState(
        localStorage.getItem("GACookiesPreferences") || null
    )

    if (GACookiesPreferences == null)
        return (
            <div className={style.wrapper}>
                <div className={style.innerWrapper}>
                    <h1 className="h4">This website uses cookies</h1>
                    <p className="ps mt-4">
                        Vipir is part of Favn Software AS. We use cookies and data provided by you
                        to continuously improve, maintain and keep our services secure as well as
                        tailor our content. You can control what data we can collect and process in
                        your privacy settings and read our privacy policy here:
                        <span>
                            <Link
                                to="/privacy"
                                onClick={() => {
                                    setGACookiesPreferences(true)
                                }}
                                className={style.link}
                            >
                                Read more
                            </Link>
                        </span>
                    </p>
                    <div className={style.grid}>
                        <button
                            className={style.button}
                            onClick={() => {
                                setGACookiesPreferences(true)
                                localStorage.setItem("GACookiesPreferences", true)
                                ga4react
                                    .initialize()
                                    .then()
                                    .catch((err) => {
                                        console.error(
                                            `Failed to set Google Analytics cookie ${err}`
                                        )
                                    })
                            }}
                        >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        )
    return <div />
}

export default CookieBanner
