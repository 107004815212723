import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"

import style from "./navbar.module.css"
import VipirIcon from "./../assets/VipirIcon.svg"
import VipirLogo from "./../assets/VipirLogo.svg"

import CONSTS from "../../CONSTS.js"

type PropType = {
    children: React.ReactNode
}

function Navbar({ children }: PropType) {
    const [view, setView] = useState(false)

    const locationHref = window.location.href.split("/")[window.location.href.split("/").length - 1]

    //Deselect dropdown when clikcing outside of it
    const wrapperRef = useRef(null)
    const useOutsideAlerter = (ref: any) => {
        useEffect(() => {
            function handleClickOutside(event: Event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setView(false)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }, [ref])
    }
    useOutsideAlerter(wrapperRef)

    return (
        <div>
            <div className={style.wrapper}>
                <div className={style.innerWrapper}>
                    <Link className="a" to="/">
                        <div className={style.inline}>
                            <img className={style.svgIcon} alt="" src={VipirIcon}></img>
                            <img className={style.svgLogo} alt="" src={VipirLogo}></img>
                        </div>
                    </Link>
                    <div className={style.links}>
                        <span className={style.linkLogicWrapper}>
                            <NavbarItem text={"Home"} link={"/"} />
                        </span>

                        <span className={style.linkLogicWrapper}>
                            <NavbarItem
                                text={"Log in"}
                                link={CONSTS.ADMIN_PANEL_URL}
                                externalLink={true}
                            />
                        </span>
                        <Link to="/order" className={style.buttonWrapper}>
                            <button className={style.button}>Try it for free</button>
                        </Link>

                        <div className={style.navCakeButtonWrapper}>
                            <button
                                aria-label={"Navigation menu"}
                                className={style.navCakeButton}
                                onClick={() => {
                                    view ? setView(false) : setView(true)
                                }}
                            >
                                <svg
                                    className={style.navCakeButtonImg}
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 300 180"
                                >
                                    <rect width="300" height="20" rx="10" fill="#2569E6" />
                                    <rect y="80" width="300" height="20" rx="10" fill="#2569E6" />
                                    <rect y="160" width="300" height="20" rx="10" fill="#2569E6" />
                                </svg>
                            </button>
                            {view && (
                                <div className={style.dropdownWrapper} ref={wrapperRef}>
                                    <Link
                                        to={"/"}
                                        onClick={() => {
                                            setView(false)
                                        }}
                                    >
                                        <div
                                            className={`${style.linkWrapperDropdown} ${
                                                locationHref === "" ? style.darkBg : style.lightBg
                                            }`}
                                        >
                                            <button className={style.link}>{"Home"}</button>
                                        </div>
                                    </Link>
                                    <a
                                        href={"https://admin.fedd.no/"}
                                        onClick={() => {
                                            setView(false)
                                        }}
                                    >
                                        <div
                                            className={`${style.linkWrapperDropdown} ${style.lightBg}`}
                                        >
                                            <button className={style.link}>{"Log in"}</button>
                                        </div>
                                    </a>
                                    <Link
                                        to={"/order"}
                                        onClick={() => {
                                            setView(false)
                                        }}
                                    >
                                        <div
                                            className={`${style.linkWrapperDropdown} ${
                                                locationHref === "order"
                                                    ? style.darkBg
                                                    : style.lightBg
                                            }`}
                                        >
                                            <button className={style.link}>
                                                {"Try it for free"}
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-16">{children}</div>
        </div>
    )
}

export default Navbar

function NavbarItem(props: { text: string; link: string; externalLink?: boolean }) {
    const externalLink = props.externalLink || false
    const location = window.location.href.split("/")[window.location.href.split("/").length - 1]
    const location2 = window.location.href.split("/")[window.location.href.split("/").length - 2]
    return (
        <div>
            {externalLink ? (
                <a href={props.link}>
                    <div className={style.linkWrapper}>
                        <button className={style.link}>{props.text}</button>
                        <span
                            className={
                                location === props.link || location2 === props.link
                                    ? style.fullLinkUnderline
                                    : style.linkUnderline
                            }
                        ></span>
                    </div>
                </a>
            ) : (
                <Link to={props.link}>
                    <div className={style.linkWrapper}>
                        <button className={style.link}>{props.text}</button>
                        <span
                            className={
                                location === props.link || location2 === props.link
                                    ? style.fullLinkUnderline
                                    : style.linkUnderline
                            }
                        ></span>
                    </div>
                </Link>
            )}
        </div>
    )
}
