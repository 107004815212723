import axios, { AxiosError, AxiosResponse } from "axios"

const enum HTTP_METHOD {
    Post = "POST",
    Get = "GET",
}

export interface IAPIError {
    raisedBy: string
    error: string
    code: number
    raw: any
}

const json_header = () => {
    return {
        accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
    }
}

const unwrapAxiosError = (error: AxiosError): IAPIError => {
    if (error.response) {
        let res = error.response as AxiosResponse
        return {
            raisedBy: "Server",
            error: "The server did not approve the request",
            code: res.status,
            raw: res.data,
        }
    } else if (error.request) {
        return {
            raisedBy: "Request",
            error: "No response received from the server",
            code: 0,
            raw: error.request,
        }
    } else {
        return {
            raisedBy: "Client",
            error: "Failed to make the request",
            raw: error.message,
            code: 0,
        }
    }
}

export { HTTP_METHOD, json_header, unwrapAxiosError }
