interface ValidationConstraint {
    field: string
    type: string
}

export type ModelValidateResult<T> =
    | { ok: true; value: T }
    | { ok: false; field: string; reason: string }

function validate<T>(n: any, constraints: ValidationConstraint[]): ModelValidateResult<T> {
    for (let c of constraints) {
        if (!n.hasOwnProperty(c.field)) {
            return { ok: false, field: c.field, reason: "missing" }
        }

        let v = n[c.field]
        switch (c.type) {
            case "string":
                if (typeof v !== "string" || v.length === 0) {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "array":
                if (!(v instanceof Array)) {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "boolean":
                if (typeof v !== "boolean") {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "date":
                if (!(v instanceof Date)) {
                    return { ok: false, field: c.field, reason: "invalid" }
                }
                break
            case "ISOdate":
                // TODO: a godo way of validating this?
                break
            default:
                return { ok: false, field: c.field, reason: "unsupported" }
        }
    }
    return { ok: true, value: n }
}

export { validate }
