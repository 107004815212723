import axios from "axios"

const templateBucketName = process.env.REACT_APP_GCP_TEMPLATE_BUCKET
if (!templateBucketName) throw new Error("REACT_APP_GCP_TEMPLATE_BUCKET is not defined")

export const gcpClient = axios.create({
    baseURL: "https://storage.googleapis.com/upload/storage/v1/b/" + templateBucketName,
})

export type StorageResult = {
    config: {}
    data: StorageObject
    headers: {}
    request: {}
    status: number
    statusText: string
}

export type StorageObject = {
    kind: "storage#object"
    id: string
    selfLink: string
    mediaLink: string
    name: string
    bucket: string
    generation: "long"
    metageneration: "long"
    contentType: string
    storageClass: string
    size: "unsigned long"
    md5Hash: string
    contentEncoding: string
    contentDisposition: string
    contentLanguage: string
    cacheControl: string
    crc32c: string
    componentCount: number
    etag: string
    kmsKeyName: string
    temporaryHold: boolean
    eventBasedHold: boolean
    retentionExpirationTime: "datetime"
    timeCreated: "datetime"
    updated: "datetime"
    timeDeleted: "datetime"
    timeStorageClassUpdated: "datetime"
    customTime: "datetime"
    metadata: {
        [key: string]: string
    }
    acl: []
    owner: {
        entity: string
        entityId: string
    }
    customerEncryption: {
        encryptionAlgorithm: string
        keySha256: string
    }
}
