export const defaultTemplate = {
    id: "000000000000000000000001",
    created_at: "0001-01-01T00:00:00Z",
    owned_by: "000000000000000000000001",
    root: {
        type: "tabbed_view",
        app_bar: true,
        components: [
            {
                type: "tab",
                components: [
                    {
                        type: "router",
                        default_route: "/",
                        components: [
                            {
                                type: "route",
                                route: "/",
                                components: [
                                    {
                                        type: "provider_guard_multi",
                                        components: [
                                            {
                                                type: "design02",
                                                components: [],
                                                header: "Din applikasjon",
                                                header_image_url:
                                                    "https://storage.googleapis.com/fedd_dev/6380b927a46b607b1999dfcd/ff687b83-cd73-4f21-88ff-3e823dfb28dc-med.jpeg",
                                                footer_redirect_path: "",
                                            },
                                        ],
                                        providers: ["newsletters", "offers"],
                                    },
                                ],
                            },
                            {
                                type: "route",
                                route: "/newsletter",
                                components: [{ type: "newsletter_page", components: [] }],
                            },
                        ],
                    },
                ],
                id: "",
                title: { en: "Home" },
                icon: { source: "", name: "" },
            },
            {
                type: "tab",
                components: [
                    {
                        type: "profile",
                        components: [],
                        title: "",
                        notification_title: "",
                        gradient: ["#834B88", "#6E095D"],
                        app_update_title: "Update",
                    },
                ],
                id: "e8bacf",
                title: { en: "Profile" },
                icon: { source: "material", name: "web" },
            },
        ],
    },
    theme: {
        type: "custom",
        background: { r: 241, g: 255, b: 241, a: 1 },
        primary: { r: 255, g: 255, b: 255, a: 1 },
        secondary: { r: 255, g: 187, b: 55, a: 1 },
        tertiary: { r: 85, g: 68, b: 154, a: 1 },
    },
    providers: [
        {
            name: "newsletters",
            url: "{env.baseurl}/companies/{env.companyname}/newsletters",
            multi: true,
            schema: {},
        },
        {
            name: "offers",
            url: "{env.baseurl}/companies/{env.companyname}/offers",
            multi: true,
            schema: {},
        },
    ],
    public: false,
    parent: "000000000000000000000000",
    is_managed: false,
}
export const defaultCompany = {
    id: "000000000000000000000001",
    company_name: "vipir_demo",
}
