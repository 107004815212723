import React from "react"
import style from "./Input.module.css"

enum InputVariants {
    primary,
    smallPrimary,
    secondary,
    smallSecondary,
    danger,
    icon,
    contained,
    outlined,
    text,
}

type PropTypes = {
    className?: string
    size?: string
    color?: string
    variant?: InputVariants
    disabled?: boolean
    disableElevation?: boolean
    name?: string
    spinnerColor?: string
    onChange?: Function
    onBlur?: any
    value?: string
    type?: any
    placeholder?: string
    label?: string
    hasError?: boolean
    errorText?: string
    pattern?: string
}

const Input = React.forwardRef(
    ({
        className = "",
        color = "secondary",
        variant = InputVariants.primary,
        disabled,
        disableElevation,
        name,
        spinnerColor,
        value,
        onChange,
        placeholder,
        size = "md",
        label,
        hasError,
        errorText,
        ...rest
    }: PropTypes) => {
        const inputChange = (title: any) => {
            if (!!onChange) {
                onChange(title)
            }
        }

        let baseStyles = ""
        let inputStyles = `select-none `
        if (disabled) {
            inputStyles +=
                variant !== InputVariants.icon
                    ? `${baseStyles} cursor-not-allowed opacity-50 ${
                          variant !== InputVariants.text ? "bg-gray-400" : ""
                      } `
                    : "cursor-not-allowed opacity-50 p-2"
        } else {
            switch (variant) {
                case InputVariants.primary: {
                    inputStyles += `border-hoverDark border-solid border w-full rounded-md mt-1`
                    break
                }
                default:
            }
        }
        if (hasError) {
            inputStyles += " border-error text-error"
        }

        return (
            <div className={`${className}`}>
                <label htmlFor={label || "id"} className={`p ${hasError ? ' text-error' : ''}`}>
                    {label}
                </label>
                
                    <input
                        id={label || "id"}
                        name={name}
                        value={value}
                        onChange={(e: any) => inputChange(e)}
                        className={`${inputStyles} border-hoverDark border-solid border w-full rounded-md mt-1 ${style["input"]} ${hasError ? ' border-error' : ''} `}
                        placeholder={placeholder}
                        {...rest}
                    />
                    {
                        hasError && <p className="text-error text-sm">{errorText || "warning"}</p>
                    }
                
            </div>
        )
    }
)

export default Input
export { InputVariants }
