import { Template } from "@favnsoftware/apptheme"
import { gcpClient, StorageResult } from "./gcp"

export async function createTemplate(
    template: Template,
    memImages: { [index: string]: File }
): Promise<string | null> {
    if (!template) throw new Error("Template is not defined")
    const templateID = crypto.randomUUID()

    const homeTab = template.root.components.find((c: any) => c.title.en === "Home")
    if (!homeTab) throw new Error("Hometab is not defined")

    const design = homeTab.components[0].components[0].components[0].components[0]
    if (!design) throw new Error("Design is not found")

    // Upload images if design02
    if (design && design.type === "design02" && Object.keys(memImages).length !== 0) {
        const [name, image] = Object.entries(memImages)[0]
        if (image) {
            const imageUploadResult = (await gcpClient.post(
                `/o?uploadType=media&name=${templateID}/${name}`,
                image
            )) as void | StorageResult
            if (imageUploadResult) {
                design.header_image_url = imageUploadResult.data.selfLink
            }
        }
    }

    // Upload template
    const templateUploadResult = (await gcpClient.post(
        `/o?uploadType=multipart&name=${templateID}/${templateID}.json`,
        template
    )) as void | StorageResult

    return templateUploadResult ? templateID : null
}
