import React from "react"
import style from "./../landingPage.module.css"
import AppIllustrations from "./../assets/BothPlatformsIllustration.webp"

function SimpleSteps() {
    return (
        <div className={style.ddWrapper}>
            <div className={style.ddGridSteps}>
                <div className={style.ddPhoneImg}>
                    <img className={style.datadrivenImg} src={AppIllustrations}></img>
                    {/*TODO: Change to AppStore/GooglePlay illsutartion of app*/}
                </div>

                <div>
                    <h1 className="h2-blue mb-4">Get your app published in less than 3 weeks</h1>
                    <p className="p mb-4">
                        Developing native mobile apps can take 6-12 months and cost tens of
                        thousands of dollars.
                        <br />
                        <br />
                        With Vipir you can have ecommerce apps created in less than 3 weeks for a
                        fraction of the costs.
                        <br />
                        <br />
                        All you have to do is:
                    </p>
                    <div className={style.stepsList}>
                        <span>1. Register you interest</span>
                        <span>2. Get a personalized demo within a week</span>
                        <span>
                            3. Fill out a short form with logos, descriptions, and other practical
                            information about the app
                        </span>
                        <span>
                            4. Wait 2-3 weeks and review the finished app ready to be published
                        </span>
                        <span> Launch!</span>
                    </div>
                </div>

                <div className={style.ddImg}>
                    <img className={style.datadrivenImg} src={AppIllustrations}></img>
                    {/*TODO: Change to AppStore/GooglePlay illsutartion of app*/}
                </div>
            </div>
        </div>
    )
}

export default SimpleSteps
