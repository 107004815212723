import React from "react"
import Footer from "../../components/footer/footer"
import style from "./privacyPolicy.module.css"
import PolicyText from "./components/policyText/policyText"

function PrivacyPolicy() {
    return (
        <div>
            <div className={style.Wrapper}>
                <div className={style.Content}>
                    <div>
                        <div className="p mt-8">
                            <PolicyText />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
