import React from "react"
import { Link } from "react-router-dom"
import style from "./pricing.module.css"
import { Locale } from "../../../../providers/Locale"

function Pricing() {
    const testimonials = [
        {
            name: "STORE",
            price: Locale().pricing.store,
            subtext: "monthly",
            description: [
                "Your webshop turned into an syncronized app",
                "Fully customized",
                "Published in AppStore & Google Play",
                "Simple analytics and metrics",
            ],
            buttonType: 1,
            buttonLink: "/order",
            bestValue: false,
        },
        {
            name: "PRO",
            price: Locale().pricing.pro,
            subtext: "monthly",
            description: [
                "Send notifications with offers and news to your customers",
                "Advanced live analytics and metrics",
                "Your webshop turned into an syncronized app",
                "Fully customized",
                "Published in AppStore & Google Play",
            ],
            buttonType: 1,
            buttonLink: "/order",
            bestValue: true,
        },
        {
            name: "ENTERPRISE",
            price: Locale().pricing.enterprise,
            subtext: "monthly",
            description: [
                "Custom built functionality specifically for you",
                "Send notifications with offers and news to your customers",
                "Advanced & tailored live analytics and metrics",
                "Your webshop turned into an syncronized app",
                "Fully customized",
                "Published in AppStore & Google Play",
            ],
            buttonType: 0,
            buttonLink: "/order",
            bestValue: false,
        },
    ]

    return (
        <div className={style.wrapper}>
            <h1 className="h2">{"Simple pricing tiers, with all you need:"}</h1>
            <div className={style.testiesGrid}>
                {testimonials.map((testimonial) => (
                    <div key={testimonial.name} className={style.card}>
                        <div className={style.contentGrid}>
                            <div>
                                <p className={style.name}>{testimonial.name}</p>
                                <p className={style.price}>{testimonial.price}</p>
                                <p className={style.subtext}>{testimonial.subtext}</p>
                                <div className={style.descriptionList}>
                                    {testimonial.description.map((description) => (
                                        <div className={style.descriptionItem} key={description}>
                                            <li className={style.description}></li>
                                            <span className={style.description}>{description}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={style.bottom}>
                                {testimonial.buttonType ? (
                                    <Link to={testimonial.buttonLink}>
                                        <button className={style.button}>Order now →</button>
                                    </Link>
                                ) : (
                                    <Link to={testimonial.buttonLink}>
                                        <button className={style.buttonLight}>Contact us →</button>
                                    </Link>
                                )}
                            </div>
                        </div>
                        {testimonial.bestValue && (
                            <div className={style.bestValue}>
                                <p>BEST VALUE</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Pricing
