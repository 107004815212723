import React from "react"
import style from "./../landingPage.module.css"
import GirlUsingShoppingApp from "./../assets/GirlUsingShoppingApp.webp"
import Illustration3x from "./../assets/3x.svg"

function Datadriven() {
    return (
        <div className={style.ddWrapper}>
            <div className={style.ddGrid}>
                <div className={style.ddPhoneImg}>
                    <img className={style.datadrivenImg} src={Illustration3x}></img>
                </div>

                <div>
                    <h1 className="h2-blue mb-4">3x higher conversion rate on phone and tablet</h1>
                    {/*Number of impressions?*/}
                    <p className="p mb-4">
                        Dedicated ecommerce apps have shown to increase digital shops' conversion
                        rates by up to 3 times compared to just having your shop available in mobile
                        browsers.
                    </p>
                    <p className="p mb-16"></p>
                </div>

                <div className={style.ddImg}>
                    <img className={style.datadrivenImg} src={Illustration3x}></img>
                </div>
            </div>
            <div className={style.ddGrid}>
                <div>
                    <img className={style.datadrivenImg} src={GirlUsingShoppingApp}></img>
                </div>

                <div>
                    <h1 className="h2-blue mb-4">Boost engagement with notifications</h1>
                    {/*Number of impressions?*/}
                    <p className="p mb-4">
                        Push notifications is a direct line to your core customers. Increase loyalty
                        and customer retention by sending offers and relevant news the effective
                        way. Notifactions and the app itself works as visible reminders of your
                        brand, and offers a shortcut to your digital shop.
                    </p>
                    <p className="p mb-16"></p>
                </div>
            </div>
        </div>
    )
}

export default Datadriven
