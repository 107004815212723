import React from "react"
import style from "./header.module.css"
import appIllustration from "./../../assets/AppIllustrations.png"
import { Link } from "react-router-dom"

function Header() {
    return (
        <div className=" p-4 flex flex-col lg:flex-row justify-center items-center">
            <div className="flex flex-col items-center pb-2 flex-1">
                <div className="max-w-xl flex-col flex items-center"> 
                    <h1 className="h1 text-4xl lg:text-7xl">
                        Launch your business mobile and run it from anywhere
                    </h1>{" "}
                    <p className="p text-xl lg:text-2xl mt-4 lg:mt-10">
                        Use Vipir to transform your webshop into an app to manage and grow your
                        business on the fly.
                    </p>
                    <Link to={"/order"}>
                        <button className={`mt-8 ${style.button}`}>Try 30 days for free →</button>
                    </Link>
                </div>
            </div>
            <div className="flex-1">
                <img className={style.headerImage} src={appIllustration} alt={""}></img>
            </div>
        </div>
    )
}

export default Header
