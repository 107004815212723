import React from "react"
import { AppTheme, Template } from "@favnsoftware/apptheme"

import { defaultTemplate, defaultCompany } from "../../constants/templateDefaults"
import { createTemplate } from "../../api/template"
import { useNavigate } from "react-router-dom"

const AppThemeDemo = () => {
    const navigate = useNavigate()

    async function onSubmitHandler(template: Template, memImages: { [index: string]: File }) {
        const templateID = await createTemplate(template, memImages)
        if (!templateID) {
            console.error("Error creating template")
            return
        }
        navigate(`/order/${templateID}`)
    }

    return (
        <div className="flex items-center justify-center mt-12">
            <div className="max-w-screen-lg">
                <h1 className="h2-blue mb-4">Customize your own app using our templates!</h1>
                <AppTheme
                    template={defaultTemplate}
                    onsubmit={onSubmitHandler}
                    company={defaultCompany}
                    isDemo={true}
                />
                <div className="mt-12 border border-primary" />
            </div>
        </div>
    )
}

export default AppThemeDemo
