import React from "react"

function PolicyText() {
    return (
        <div>
            <h1>Cookie & Privacy Policy for Favn Software AS</h1>
            <br />
            <h2>Privacy Policy for Favn Software AS</h2>
            <p>
                At Favn Software AS, accessible from vipir.no, vipir.de and favn.com, one of our
                main priorities is the privacy of our visitors. This Privacy Policy document
                contains types of information that is collected and recorded by Favn Software AS and
                how we use it.
            </p>
            <p>
                If you have additional questions or require more information about our Privacy
                Policy, do not hesitate to contact us.
            </p>
            <p>
                This Privacy Policy applies only to our online activities and is valid for visitors
                to our website with regards to the information that they shared and/or collect in
                Favn Software AS. This policy is not applicable to any information collected offline
                or via channels other than this website.
            </p>
            <h3>Consent</h3>
            <p>
                By using our website, you hereby consent to our Privacy Policy and agree to its
                terms.
            </p>
            <h3>Information we collect</h3>
            <p>
                The personal information that you are asked to provide, and the reasons why you are
                asked to provide it, will be made clear to you at the point we ask you to provide
                your personal information.
            </p>
            <p>
                If you contact us directly, we may receive additional information about you such as
                your name, email address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may choose to provide.
            </p>
            <p>
                When you register for an Account, we may ask for your contact information, including
                items such as name, company name, address, email address, and telephone number.
            </p>
            <h3>How we use your information</h3>
            <p>We use the information we collect in various ways, including to:</p>
            <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>
                    Communicate with you, either directly or through one of our partners, including
                    for customer service, to provide you with updates and other information relating
                    to the website, and for marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>
            <h3>Log Files</h3>
            <p>
                Favn Software AS follows a standard procedure of using log files. These files log
                visitors when they visit websites. All hosting companies do this and a part of
                hosting services' analytics. The information collected by log files include internet
                protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and
                time stamp, referring/exit pages, and possibly the number of clicks. These are not
                linked to any information that is personally identifiable. The purpose of the
                information is for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
            </p>
            <h3>Cookies and Web Beacons</h3>
            <p>
                Like any other website, Favn Software AS uses 'cookies'. These cookies are used to
                store information including visitors' preferences, and the pages on the website that
                the visitor accessed or visited. The information is used to optimize the users'
                experience by customizing our web page content based on visitors' browser type
                and/or other information.
            </p>
            <p>
                For more general information on cookies, please read{" "}
                <a href="#cookies"> the Cookies article below</a>.
            </p>
            <h3>Advertising Partners Privacy Policies</h3>
            <p>
                You may consult this list to find the Privacy Policy for each of the advertising
                partners of Favn Software AS.
            </p>
            <p>
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or
                Web Beacons that are used in their respective advertisements and links that appear
                on this website, which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are used to measure the
                effectiveness of their advertising campaigns and/or to personalize the advertising
                content that you see on websites that you visit.
            </p>
            <p>
                Note that Favn Software AS has no access to or control over these cookies that are
                used by third-party advertisers.
            </p>
            <h3>Third Party Privacy Policies</h3>
            <p>
                Favn Software AS's Privacy Policy does not apply to other advertisers or websites.
                Thus, we are advising you to consult the respective Privacy Policies of these
                third-party ad servers for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options.{" "}
            </p>
            <p>
                You can choose to disable cookies through your individual browser options. To know
                more detailed information about cookie management with specific web browsers, it can
                be found at the browsers' respective websites.
            </p>
            <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p>
                Request that a business that collects a consumer's personal data disclose the
                categories and specific pieces of personal data that a business has collected about
                consumers.
            </p>
            <p>
                Request that a business delete any personal data about the consumer that a business
                has collected.
            </p>
            <p>
                Request that a business that sells a consumer's personal data, not sell the
                consumer's personal data.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If you would like to
                exercise any of these rights, please contact us.
            </p>
            <h3>GDPR Data Protection Rights</h3>
            <p>
                We would like to make sure you are fully aware of all of your data protection
                rights. Every user is entitled to the following:
            </p>
            <p>
                The right to access – You have the right to request copies of your personal data. We
                may charge you a small fee for this service.
            </p>
            <p>
                The right to rectification – You have the right to request that we correct any
                information you believe is inaccurate. You also have the right to request that we
                complete the information you believe is incomplete.
            </p>
            <p>
                The right to erasure – You have the right to request that we erase your personal
                data, under certain conditions.
            </p>
            <p>
                The right to restrict processing – You have the right to request that we restrict
                the processing of your personal data, under certain conditions.
            </p>
            <p>
                The right to object to processing – You have the right to object to our processing
                of your personal data, under certain conditions.
            </p>
            <p>
                The right to data portability – You have the right to request that we transfer the
                data that we have collected to another organization, or directly to you, under
                certain conditions.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If you would like to
                exercise any of these rights, please contact us.
            </p>
            <h3>Children's Information</h3>
            <p>
                Another part of our priority is adding protection for children while using the
                internet. We encourage parents and guardians to observe, participate in, and/or
                monitor and guide their online activity.
            </p>
            <p>
                Favn Software AS does not knowingly collect any Personal Identifiable Information
                from children under the age of 13. If you think that your child provided this kind
                of information on our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such information from our
                records.
            </p>
            <h3 className="cookie-policy-h1">How to contact us</h3>
            If you have questions or comments about this notice, you may email us at
            managment@favn.com or by post to:
            <p>
                Favn Software AS
                <br />
                Kjøpmannsgata 59
                <br />
                7011, Trondheim, Trøndelag <br />
                Norway
            </p>
            <p>
                If you are a resident in the European Economic Area, the "data controller" of your
                personal information is Favn Software AS.
            </p>{" "}
            <p>
                Favn Software AS has appointed Sveinung Øverland to be its representative in the
                EEA.{" "}
            </p>
            You can contact them directly regarding the processing of your information by Favn
            Software AS, by email at managment@favn.com, or by post to:
            <p>
                Favn Software AS
                <br />
                Kjøpmannsgata 59
                <br />
                7011, Trondheim, Trøndelag <br />
                Norway
            </p>
            <br />
            <br />
            <h2 className="cookie-policy-h1" id={"cookies"}>
                Cookie Policy
            </h2>
            <br />
            <h3>What are cookies?</h3>
            <div className="cookie-policy-p">
                <p>
                    This Cookie Policy explains what cookies are and how we use them, the types of
                    cookies we use i.e, the information we collect using cookies and how that
                    information is used, and how to manage the cookie settings.
                </p>{" "}
                <p>
                    Cookies are small text files that are used to store small pieces of information.
                    They are stored on your device when the website is loaded on your browser. These
                    cookies help us make the website function properly, make it more secure, provide
                    better user experience, and understand how the website performs and to analyze
                    what works and where it needs improvement.
                </p>
            </div>
            <br />
            <h3>How do we use cookies?</h3>
            <div className="cookie-policy-p">
                <p>
                    As most of the online services, our website uses first-party and third-party
                    cookies for several purposes. First-party cookies are mostly necessary for the
                    website to function the right way, and they do not collect any of your
                    personally identifiable data.
                </p>{" "}
                <p>
                    The third-party cookies used on our website are mainly for understanding how the
                    website performs, how you interact with our website, keeping our services
                    secure, providing advertisements that are relevant to you, and all in all
                    providing you with a better and improved user experience and help speed up your
                    future interactions with our website.
                </p>
            </div>
            <br />
            <h3>Types of Cookies we use</h3>
            <div className="cky-audit-table-element">
                <h4>Analytics</h4>

                <div className="cookie-policy-p">
                    <p>
                        Cookies used for analytics help collect data that allows services to
                        understand how users interact with a particular service. These insights
                        allow services both to improve content and to build better features that
                        improve the user’s experience. Some cookies help sites understand how their
                        visitors engage with their properties. For example, Google Analytics, a
                        Google product that helps site and app owners understand how people engage
                        with a service, uses a set of cookies to collect information and report site
                        usage statistics without personally identifying individual visitors to
                        Google. ‘_ga’ is the main cookie used by Google Analytics. ‘_ga’ enables a
                        service to distinguish one user from another and lasts for 2 years. It’s
                        used by any site that implements Google Analytics, including Google
                        services.
                    </p>
                </div>
            </div>
            <br />
            <h3>Manage cookie preferences</h3>
            <div>
                <p>
                    Different browsers provide different methods to block and delete cookies used by
                    websites. You can change the settings of your browser to block/delete the
                    cookies. Listed below are the links to the support documents on how to manage
                    and delete cookies from the major web browsers.
                </p>{" "}
                <p>
                    Chrome:{" "}
                    <a href="https://support.google.com/accounts/answer/32050" target="_blank">
                        https://support.google.com/accounts/answer/32050
                    </a>
                </p>
                <p>
                    Safari:{" "}
                    <a
                        href="https://support.apple.com/en-in/guide/safari/sfri11471/mac"
                        target="_blank"
                    >
                        https://support.apple.com/en-in/guide/safari/sfri11471/mac
                    </a>
                </p>
                <p>
                    Firefox:{" "}
                    <a
                        href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                        target="_blank"
                    >
                        https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
                    </a>
                </p>
                <p>
                    Internet Explorer:{" "}
                    <a
                        href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                        target="_blank"
                    >
                        https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
                    </a>
                </p>
                <p>
                    If you are using any other web browser, please visit your browser’s official
                    support documents.
                </p>
            </div>
            <br />
        </div>
    )
}

export default PolicyText
