import React, { useEffect, useState } from "react"
import style from "./footer.module.css"
import { Link } from "react-router-dom"

import vipirLogo from "./../assets/VipirLogo.svg"
import { Locale } from "../../providers/Locale"

const Footer = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.contactWrapper}>
                <Link to={"/order"}>
                    <button className={`mt-8 ${style.button}`}>Try 30 days for free →</button>
                </Link>
            </div>
            <div className={style.footerWrapper}>
                <div className={style.footerGrid}>
                    <div className={style.footerItem}>
                        <p className="ps">{Locale().email}</p>
                    </div>
                    <div className={style.footerItem}>
                        <a className="ps a-white" href={"./privacyPolicy"}>
                            Cookie & Privacy Policy
                        </a>
                    </div>
                    <div className={style.footerItem}>
                        <p className="ps">
                            Copyright © 2022 <br /> Favn Software
                        </p>
                    </div>
                    <div className={style.footerItem}>
                        <p className="ps">
                            Kjøpmannsgata 59 <br />
                            NO-7011 Trondheim
                        </p>
                    </div>
                </div>
                {/*<div className={style.vipirLogoWrapper}>
                    <img className={style.vipirLogo} src={vipirLogo} alt=""></img>
    </div>*/}
            </div>
        </div>
    )
}

export default Footer
