import React from "react"
import style from "./sellingPoints.module.css"

import GraphIcon from "./../../assets/WorksWithYourSystem.svg"
import AppIcon from "./../../assets/Syncronization.svg"
import ShopIcon from "./../../assets/AllDoneForYou.svg"

function SellingPoints() {
    const points = [
        {
            title: "Works with your site",
            illustration: GraphIcon,
            text: "Turn your website into an app in less than 3 weeks. Compatible with all your plugins.",
        },
        {
            title: "Automatically synced",
            illustration: AppIcon,
            text: "Includes all of the content and functionality of your website, no extra work needed.",
        },
        {
            title: "All done for you",
            illustration: ShopIcon,
            text: "We create, publish, and maintain the app, so you can focus on running your business.",
        },
        /*{ title: "Fleksible tilbud", illustration: CouponIcon, text: "Occaecat cupidatat non proident, sunt in culpa qui officia deserunt.  Compatable with your analytic tools and third-party plugins." },*/
    ]

    return (
        <div className={style.SPWrapper}>
            <div className={style.SPWrapperCorner}>
                <div className={style.SPWrapperCornerInner}></div>
            </div>
            <div className={style.SPContent}>
                <div className={style.sellingPointsGrid}>
                    {points.map((point) => (
                        <div className={style.SPItem} key={point.title}>
                            <img
                                className={style.SPImg}
                                src={point.illustration}
                                alt={point.title}
                            ></img>
                            <h2 className="h3-white mt-4">{point.title}</h2>
                            <p className="p p-white">{point.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SellingPoints
