const backend_scheme = process.env.REACT_APP_BACKEND_SCHEME
const backend_url = process.env.REACT_APP_BACKEND_URL
const backend_port = process.env.REACT_APP_BACKEND_PORT

const admin_panel_scheme = process.env.REACT_APP_ADMIN_PANEL_SCHEME
const admin_panel_url = process.env.REACT_APP_ADMIN_PANEL_URL
const admin_panel_port = process.env.REACT_APP_ADMIN_PANEL_PORT

const CONSTS = {
    ADMIN_PANEL_URL: `${admin_panel_scheme}://${admin_panel_url}:${admin_panel_port}`,
    BACKEND_BASE_URL: `${backend_scheme}://${backend_url}:${backend_port}`,
}

export default CONSTS
