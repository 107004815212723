import React from "react"
import Paper from "../../../components/base/Paper"
import FlatDesign from "../../../components/assets/flatdesign_mail_sendt.svg"
import Error from "../../../components/assets/flatdesign_error.svg"
import Duplicate from "../../../components/assets/flatdesign_duplicate.svg"

import { useNavigate } from "react-router-dom";


import { SubmittedState } from "../order"

type PropTypes = {
    onSubmit: (state: SubmittedState) => void

    submitState: SubmittedState
}

const Submitted = ({submitState, onSubmit }: PropTypes) => {

    const navigate = useNavigate();

    let header = "Thanks for showing your interest!"
    let text = "We will get back to you as soon as possible."
    let image = FlatDesign

    switch (submitState) {
        case SubmittedState.FAILED:
            header = "Something went wrong"
            text = "Please try again later"
            image = Error
            break;
        case SubmittedState.SUBMITTED:
            header = "Thanks for showing your interest!"
            text = "We will get back to you as soon as possible."
            image = FlatDesign

        break;
        case SubmittedState.DUPLICATE:
            header = "You have already signed up!"
            text = "We will get back to you as soon as possible."
            image = Duplicate
        break;

        default:
            break;
    }


    return (
        <div className="pt-20 md:pt-0 md:h-screen flex justify-center bg-slate-50 items-center">
            <Paper className="max-w-6xl flex flex-col items-center p-3 md:p-40 ">
                <h1 className="h1">{header}</h1>
                <br />
                <h1 className="h3">{text}</h1>
                <img className="w-3/12 my-12" alt="mail icon" src={image}/>
                <button
                className={"mt-2 mb-2 bg-primary w-full py-5 text-white rounded-lg text-xl font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:bg-secondary"}
                    onClick={() => {
                        navigate("/")
                    }}
                >
                    Go back Home
                </button>
                <button
                className={"border-solid border-primary w-full py-4 text-primary rounded-lg text-xl font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:text-secondary"}
                    onClick={() => {
                        onSubmit(SubmittedState.NOT_SUBMITTED)
                    }}
                >
                    Add another website
                </button>
            </Paper>
        </div>
    )
}

export default Submitted
