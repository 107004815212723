import React from "react"

type PropTypes = {
    children: React.ReactNode
    className?: string
}

const Paper = ({ className, ...rest}: PropTypes) => {

    return <div className={`bg-white rounded-lg shadow-lg ${className}`} {...rest}/>
}

export default Paper