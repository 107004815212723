type PricingType = {
    store: string
    pro: string
    enterprise: string
}

class LocaleClass {
    email: string
    country: string
    pricing: PricingType

    constructor(email: string, country: string, pricing: PricingType) {
        this.email = email
        this.country = country
        this.pricing = pricing
    }
}

export const Locale = () => {
    // Default to norwegian
    let locale: LocaleClass = new LocaleClass("contact@vipir.no", "no-NO", {
        store: "1.490 NOK",
        pro: "1.890 NOK",
        enterprise: "> 3.000 NOK",
    })

    switch (navigator.language) {
        case "de-DE":
            locale = {
                email: "contact@vipir.de",
                country: "de-DE",
                pricing: {
                    store: "€149",
                    pro: "€189",
                    enterprise: "> €300",
                },
            }
            return locale
        default:
            return locale
    }
}
